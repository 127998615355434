import React, { useState, useEffect, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import styled from 'styled-components';
import {
  getUrlParam, auth, getInstructorsString, getLocationString, UserContext, mapEvent,
} from '@helpers';
import {
  Container,
  Hero,
  Loader,
  StaticError,
  StaticWarning,
  StaticSuccess,
  Table,
  Amount,
  VoucherRow,
  PaymentButton,
} from '@components';

import MESSAGES from '@messages';
import {
  NEW_API_URL,
  DAY_NAMES,
  MONTH_NAMES,
  PAYMENT_STATUSES,
} from '@constants';

const StyledContainer = styled(Container)`
  text-align: center;
`;
const DetailsWraper = styled.div`
  ${(props) => props.disabled && `
    opacity: 0.5;
  `}
`;

const getControl = (location) => getUrlParam('id', location);

const getDetailStatus = (status) => {
  if (status === PAYMENT_STATUSES.NEW) return { success: '', warning: '' };
  if (status === PAYMENT_STATUSES.PENDING) return { success: MESSAGES.PAYMENT_PENDING, warning: '' };
  if (status === PAYMENT_STATUSES.REFUNDED) return { success: MESSAGES.PAYMENT_REFUNDED, warning: '' };
  if (status === PAYMENT_STATUSES.PAID) return { success: MESSAGES.PAYMENT_COMPLETED, warning: '' };

  if ([
    PAYMENT_STATUSES.CANCELED,
    PAYMENT_STATUSES.FAILED,
    PAYMENT_STATUSES.EXPIRED,
    PAYMENT_STATUSES.UNKNOWN,
  ].includes(status)) {
    return { success: '', warning: status === PAYMENT_STATUSES.EXPIRED ? MESSAGES.PAYMENT_EXPIRED : MESSAGES.PAYMENT_CANCELLED };
  }
  return { warning: MESSAGES.PAYMENT_UNKNOWN, success: '' };
};

const Details = ({ details }) => {
  const {
    payment_url, items, amount, status, expiration_date, voucher, id,
  } = details || {};

  if (!id) return null;

  const { success, warning } = getDetailStatus(status);

  const disabled = (warning || success) && status !== PAYMENT_STATUSES.PENDING;

  return (
    <>
      {success && <StaticSuccess mb={[2, 4]} fontSize={[1, 2]}>{success}</StaticSuccess>}
      {warning && <StaticWarning mb={[2, 4]} fontSize={[1, 2]}>{warning}</StaticWarning>}
      <DetailsWraper disabled={disabled}>
        <Table>
          <thead>
            <tr>
              <th>{MESSAGES.CLASS}</th>
              <th>{MESSAGES.PARTICIPANT}</th>
              <th>{MESSAGES.AMOUNT_TIMES_PRICE}</th>
            </tr>
          </thead>
          <tbody>
            {items.map(({ event, participant, amount: itemAmount }) => {
              const {
                events_length,
                month,
                summary,
                instructors,
                day_index,
                start_time,
                end_time,
                location,
              } = mapEvent(event) || {};

              return (
                <tr key={`${event.id}-${participant?.id}`}>
                  <td>
                    <div>
                      <strong>{summary}</strong>
                      {typeof month === 'number' ? ` (${MONTH_NAMES?.[month]})` : ''}
                    </div>
                    <div>
                      {`${DAY_NAMES?.[day_index] || ''} ${start_time || ''} - ${end_time || ''}`}
                    </div>
                    {instructors?.length && (
                      <div>
                        {getInstructorsString(instructors)}
                      </div>
                    )}
                    {getLocationString(location)}
                  </td>
                  <td>{participant?.name}</td>
                  <td>
                    {events_length}
                    {' x '}
                    {itemAmount}
                    {MESSAGES.PLN}
                    {' '}
                    =
                    {' '}
                    <strong>
                      {itemAmount * events_length}
                      {MESSAGES.PLN}
                    </strong>
                  </td>
                </tr>
              );
            })}
            <VoucherRow voucher={voucher} />
          </tbody>
        </Table>
        <Amount>
          {amount}
        </Amount>
      </DetailsWraper>
      {!disabled && payment_url && <PaymentButton date={expiration_date} href={payment_url} />}
    </>
  );
};

Details.propTypes = {
  details: PropTypes.shape({}),
};

const SummaryPage = ({ location }) => {
  const [state, setState] = useState({
    isLoading: true,
    globalError: null,
    details: null,
    error: null,
  });
  const control = getControl(location);
  const { client } = useContext(UserContext);

  const setError = (message = MESSAGES.DEFAULT_ERROR) => {
    setState({
      isLoading: false,
      globalError: message,
      details: null,
      error: message,
    });
  };

  useEffect(() => {
    if (!client.email) return;
    const token = auth.getToken();
    if (token) {
      axios
        .get(`${NEW_API_URL}/payment/${control}`)
        .then(({ status, data }) => {
          if (status === 200) {
            setState({
              isLoading: false,
              globalError: null,
              details: data,
              error: null,
            });
          } else {
            setError();
          }
        }).catch(({ response: { data: { statusCode } = {} } = {} }) => {
          setError(statusCode === 404 ? MESSAGES.NOT_FOUND : MESSAGES.DEFAULT_ERROR);
        });
    } else {
      setError(MESSAGES.NOT_FOUND);
    }
  }, [control, client.email]);

  const { isLoading, globalError, details } = state;
  const detailsId = details?.id;

  const momoizedDetails = useMemo(() => details, [detailsId]);

  return (
    <StyledContainer py={1}>
      <Hero
        title={MESSAGES.ORDER_SUMMARY}
        center
      // {...hero}
      //   seo={seo}
      />
      {/* eslint-disable-next-line no-nested-ternary */}
      {globalError
        ? <StaticError fontSize={1}>{globalError}</StaticError>
        : isLoading ? <Loader /> : <Details details={momoizedDetails} />}
    </StyledContainer>
  );
};
SummaryPage.propTypes = {
  location: PropTypes.shape({
    href: PropTypes.string,
  }),
};

export default SummaryPage;
